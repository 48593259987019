import React from "react";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo";
import useSiteInfoAssets from "~/hooks/useSiteInfoAssets";
import Footer from "~/components/global/Footer";
import Header from "~/components/global/Header/Header";
import ResourcesHeader from "~/components/global/ResourcesHeader";

export default function Layout({
	wpPage,
	wp,
	children,
	showResourcesHeader = false,
	resourcesHeaderLight = false,
	hideFooter = false,
	hideFooterCta = false,
	categories,
	activeSearchQuery,
	onQuery,
	setQuery,
}) {
	const seo = useSiteInfoAssets();

	return (
		<SEOContext.Provider value={{ global: seo }}>
			{/* Resources header, header */}
			{showResourcesHeader ? (
				<ResourcesHeader
					// activeSearchQuery={activeSearchQuery}
					// onQuery={onQuery}
					setQuery={setQuery}
					resourcesHeaderLight={resourcesHeaderLight}
				/>
			) : (
				<Header data={wp.header} showPromoBar={wpPage?.uri === "/"} />
			)}
			{/* {resourcesHeaderLight} */}
			<main>{children}</main>

			{/* Footer */}
			{!hideFooter && <Footer data={wp.footer} hideFooterCta={hideFooterCta} />}
		</SEOContext.Provider>
	);
}
